import type { LocaleObject } from "yup";
const toStringCp = Object.prototype.toString;
const errorToString = Error.prototype.toString;
const regExpToString = RegExp.prototype.toString;
const symbolToString = typeof Symbol !== "undefined" ? Symbol.prototype.toString : () => "";
const SYMBOL_REGEXP = /^Symbol\((.*)\)(.*)$/;

const printNumber = (val: number) => {
  if (val !== +val) return "NaN";

  const isNegativeZero = val === 0 && 1 / val < 0;

  return isNegativeZero ? "-0" : `${val}`;
};

const printSimpleValue = (val: unknown, quoteStrings = false) => {
  if (val === null || val === true || val === false) return `${val}`;

  if (typeof val === "number") return printNumber(val);

  if (typeof val === "string") return quoteStrings ? `"${val}"` : val;

  if (typeof val === "function") return `[Function ${val.name || "anonymous"}]`;

  if (typeof val === "symbol") return symbolToString.call(val).replace(SYMBOL_REGEXP, "Symbol($1)");

  const tag = toStringCp.call(val).slice(8, -1);

  if (tag === "Date")
    return Number.isNaN((val as Date).getTime()) ? `${val}` : (val as Date).toISOString();

  if (tag === "Error" || val instanceof Error) return `[${errorToString.call(val)}]`;

  if (tag === "RegExp") return regExpToString.call(val);

  return null;
};

const printValue = (value: string, quoteStrings: boolean) => {
  const result = printSimpleValue(value, quoteStrings);

  if (result !== null) return result;

  return JSON.stringify(
    value,
    function (key, value) {
      const result = printSimpleValue(this[key], quoteStrings);

      if (result !== null) return result;

      return value;
    },
    2,
  );
};

type FormatErrorParams = {
  path: string;
  type: string;
  value: string;
  originalValue: string;
};

// Based on https://github.com/jquense/yup/blob/2973d0a/src/locale.js
const mixed: LocaleObject["mixed"] = {
  default: ({ path }: { path: string }) => t<{
    path: string;
  }>`
      @key formik.errors.default
      @def Field {path} is invalid
      ${{ path }}
  `,
  required: ({ path }: { path: string }) => t<{
    path: string;
  }>`
      @key formik.errors.field_required
      @def Field {path} is required
      ${{ path }}
    `,
  oneOf: ({ path, values }: { path: string; values: string }) => t<{
    path: string;
    values: string;
  }>`
      @key formik.errors.oneOf
      @def Field {path} must be one of {values}
      ${{ path, values }}
    `,
  notOneOf: ({ path, values }: { path: string; values: string }) => t<{
    path: string;
    values: string;
  }>`
      @key formik.errors.notOneOf
      @def Field {path} must not be one of {values}
      ${{ path, values }}
    `,
  notType: ({ path, type, value, originalValue }: FormatErrorParams) => {
    const isCast = originalValue != null && originalValue !== value;
    const finalValue = printValue(value, true);
    const originalValueFinal = printValue(originalValue, true);

    if (value === null) {
      return t<{
        path: string;
        type: string;
      }>`
        @key formik.errors.notType.null
        @def Field {path} must be a {type}, but the final value was: null
        ${{ path, type }}
      `;
    }

    if (isCast) {
      return t<{
        path: string;
        type: string;
        value: string;
        originalValue: string;
      }>`
        @key formik.errors.notType.cast
        @def Field {path} must be a {type}, but the final value was: {value} (cast from {originalValue}).
        ${{ path, type, value: finalValue, originalValue: originalValueFinal }}
      `;
    }

    return t<{
      path: string;
      type: string;
      value: string;
    }>`
      @key formik.errors.notType.default
      @def Field {path} must be a {type} but the final value was: {value}
      ${{ path, type, value: finalValue }}
      `;
  },
};

const string: LocaleObject["string"] = {
  length: ({ path, length }: { path: string; length: number }) => t<{
    path: string;
    length: number;
  }>`
      @key formik.errors.string.length
      @def Field {path} must be exactly {length} characters
      ${{ path, length }}
    `,
  min: ({ path, min }: { path: string; min: number }) => t<{
    path: string;
    min: number;
  }>`
      @key formik.errors.string.min
      @def Field {path} must be at least {min} characters
      ${{ path, min }}
    `,
  max: ({ path, max }: { path: string; max: number }) => t<{
    path: string;
    max: number;
  }>`
      @key formik.errors.string.max
      @def Field {path} must be at most {max} characters
      ${{ path, max }}
    `,
  email: ({ path }: { path: string }) => t<{
    path: string;
  }>`
      @key formik.errors.string.email
      @def Field {path} must be a valid email
      ${{ path }}
    `,
  url: ({ path }: { path: string }) => t<{
    path: string;
  }>`
      @key formik.errors.string.url
      @def Field {path} must be a valid URL
      ${{ path }}
    `,
  trim: ({ path }: { path: string }) => t<{
    path: string;
  }>`
      @key formik.errors.string.trim
      @def Field {path} must not have leading or trailing whitespace
      ${{ path }}
    `,
  lowercase: ({ path }: { path: string }) => t<{
    path: string;
  }>`
      @key formik.errors.string.lowercase
      @def Field {path} must be a lowercase string
      ${{ path }}
    `,
  uppercase: ({ path }: { path: string }) => t<{
    path: string;
  }>`
      @key formik.errors.string.uppercase
      @def Field {path} must be a uppercase string
      ${{ path }}
    `,
};

const number: LocaleObject["number"] = {
  min: ({ path, min }: { path: string; min: number }) => t<{
    path: string;
    min: number;
  }>`
      @key formik.errors.number.min
      @def Field {path} must be greater than or equal to {min}
      ${{ path, min }}
    `,
  max: ({ path, max }: { path: string; max: number }) => t<{
    path: string;
    max: number;
  }>`
      @key formik.errors.number.max
      @def Field {path} must be less than or equal to {max}
      ${{ path, max }}
    `,
  lessThan: ({ path, less }: { path: string; less: number }) => t<{
    path: string;
    less: number;
  }>`
      @key formik.errors.number.lessThan
      @def Field {path} must be less than {less}
      ${{ path, less }}
    `,
  moreThan: ({ path, more }: { path: string; more: number }) => t<{
    path: string;
    more: number;
  }>`
      @key formik.errors.number.moreThan
      @def Field {path} must be greater than {more}
      ${{ path, more }}
    `,
  positive: ({ path }: { path: string }) => t<{
    path: string;
  }>`
      @key formik.errors.number.positive
      @def Field {path} must be a positive number
      ${{ path }}
    `,
  negative: ({ path }: { path: string }) => t<{
    path: string;
  }>`
      @key formik.errors.number.negative
      @def Field {path} must be a negative number
      ${{ path }}
    `,
  integer: ({ path }: { path: string }) => t<{
    path: string;
  }>`
      @key formik.errors.number.integer
      @def Field {path} must be an integer
      ${{ path }}
    `,
};

const date: LocaleObject["date"] = {
  min: ({ path, min }: { path: string; min: string | Date }) => t<{
    path: string;
    min: string;
  }>`
      @key formik.errors.date.min
      @def Field {path} field must be later than {min}
      ${{ path, min: typeof min === "string" ? min : min.toISOString() }}
    `,
  max: ({ path, max }: { path: string; max: string | Date }) => t<{
    path: string;
    max: string;
  }>`
      @key formik.errors.date.max
      @def Field {path} field must be at earlier than {max}
      ${{ path, max: typeof max === "string" ? max : max.toISOString() }}
    `,
};

const boolean: LocaleObject["boolean"] = {
  isValue: ({ path, value }: { path: string; value: boolean }) => t<{
    path: string;
    value: boolean;
  }>`
      @key formik.errors.boolean.isValue
      @def Field {path} field must be {value}
      ${{ path, value }}
    `,
};

const object: LocaleObject["object"] = {
  noUnknown: ({ path }: { path: string }) => t<{
    path: string;
  }>`
      @key formik.errors.object.noUnknown
      @def Field {path} field must not contain any unknown properties
      ${{ path }}
    `,
};

const array: LocaleObject["array"] = {
  min: ({ path, min }: { path: string; min: number }) => t<{
    path: string;
    min: number;
  }>`
      @key formik.errors.array.min
      @def Field {path} field must have at least {min} items
      ${{ path, min }}
    `,
  max: ({ path, max }: { path: string; max: number }) => t<{
    path: string;
    max: number;
  }>`
      @key formik.errors.array.max
      @def Field {path} field must have less than or equal to {max} items
      ${{ path, max }}
    `,
};

const TranslatedLocale = {
  mixed,
  string,
  number,
  object,
  date,
  boolean,
  array,
};

export default TranslatedLocale;
